import * as React from "react";
import { IntlProvider } from "react-intl";
import { graphql, navigate } from "gatsby";
import { useEffect } from "react";
import _ from "lodash";

import { Helmet } from "react-helmet";
import SeoStructure from "../../components/seo-structure";
import Layout from "../../components/layout";
import Posts from "../../components/posts";

import LocalesContext from "../../context/LocalesContext";
import messages from "../../../i18n-translations.json";

const IndexPage = ({ data }) => {
  const allWpCategory = data?.allWpCategory;

  useEffect(() => {
    if (_.isEmpty(allWpCategory)) return navigate("/");
  }, [allWpCategory]);

  if (_.isEmpty(allWpCategory)) return null;

  return (
    <>
      <Posts allWpCategory={allWpCategory} />
    </>
  );
};

const MainPage = (props) => {
  return (
    <IntlProvider locale={"de"} messages={messages["de"]}>
      <LocalesContext.Provider
        value={{
          slug: "",
          avaiableLangs: ["en", "de", "es"],
          pageNumber: 0,
        }}
      >
        <Layout props={props}>
          <Helmet>
            <script
              async
              src="https://boot.pbstck.com/v1/tag/8c704138-0cf5-43be-9bf5-416e674a06d9"
              type="text/javascript"
            />
          </Helmet>
          <SeoStructure
            title={messages["de"].seo.title}
            description={messages["de"].seo.description}
            slug="/de"
          >
            <script type="application/ld+json">
              {JSON.stringify({
                "@context": "https://schema.org/",
                "@type": "BreadcrumbList",
                itemListElement: [
                  {
                    "@type": "ListItem",
                    position: 1,
                    name: messages["de"].seo.title,
                  },
                ],
              })}
            </script>
          </SeoStructure>
          <IndexPage data={props.data} />
        </Layout>
      </LocalesContext.Provider>
    </IntlProvider>
  );
};

export default MainPage;

export const query = graphql`
  query ($defaultCategorySlugs: [String]) {
    allWpCategory(
      filter: {
        slug: { nin: $defaultCategorySlugs }
        language: { slug: { eq: "de" } }
      }
    ) {
      nodes {
        name
        slug
        posts {
          nodes {
            id
            slug
            title
            excerpt
            originalSlug
            featuredImage {
              node {
                title
                mediaItemUrl
                mediaDetails {
                  filteredSizes {
                    name
                    sourceUrl
                  }
                  sizes {
                    name
                  }
                }
              }
            }
            seo {
              title
              metaDesc
              focuskw
              metaKeywords
              metaRobotsNoindex
              metaRobotsNofollow
              opengraphTitle
              opengraphDescription
              opengraphImage {
                altText
                sourceUrl
                srcSet
              }
              twitterTitle
              twitterDescription
              twitterImage {
                altText
                sourceUrl
                srcSet
              }
              canonical
              cornerstone
              schema {
                articleType
                pageType
                raw
              }
            }
          }
        }
        language {
          slug
        }
      }
    }
  }
`;
